import React from "react"
import { Pie } from 'react-chartjs-2';
import 'chartjs-plugin-labels';
import './module-dosha-test.css'

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import iconVata from '../assets/img/dosha_vata.svg'
import iconPitta from '../assets/img/dosha_pitta.svg'
import iconKapha from '../assets/img/dosha_kapha.svg'



class DoshaTest extends React.Component {
    
    constructor(props) {
        super(props);

        this.state = {
            questions: props.questions,
            currentQuestion: {title: null, type:null, dosha: {slug: null}},
            currentQuestionIndex: 1,
            doshaStock: {vata: 0, pitta: 0, kapha: 0},
            testStatus: null,
            doshaCombo: null
        }

        this.user = {
            questions: [],
            data: [],
            dosha: {
                vata: 0,
                pitta: 0,
                kapha: 0,
            },
            dominant: []
        }

        this.limit = 27;
        this.groupMax = this.limit / 3;
        this.ratio = {
            dual: 0.067,
            tri: 0.029 
        }

        this.handleNext     = this.handleNext.bind(this);
        this.handlePrevious = this.handlePrevious.bind(this);
        this.handleResult   = this.handleResult.bind(this);
    }


    componentDidMount() {

        this.setState({currentQuestion: this.drawQuestion()});
        this.setState({testStatus: 'answering'});

        //this.test()
    }


    reduceQuestion( qid ) {

        let new_questions =  this.state.questions.filter(function( obj ) {
            return obj.id !== qid;
        });

        this.setState({questions: new_questions});
    }


    drawQuestion() {

        do {

            var question = this.state.questions[Math.floor(Math.random() * this.state.questions.length)];
            var validated = this.validateQuestion( question.dosha.slug );

        } while ( ! validated );

        this.reduceQuestion( question.id );

    return question;
    }


    validateQuestion( currentQuestionDosha ) {

        return this.state.doshaStock[currentQuestionDosha] < this.groupMax;
    }


    handleResult() {

        this.handleNext(false);
        
        this.setState({testStatus: 'completed'});
        
        let dominant = this.findDominant( this.user.dosha );
        
    }



    handleNext(render = true) {

        let wrap = document.getElementById('dosha-test');
        let answer = document.getElementById('answer');

        let currentDosha = this.state.currentQuestion.dosha.slug;
        let stockDosha = this.state.doshaStock;
            stockDosha[currentDosha] = stockDosha[currentDosha] +1;
        this.setState({doshaStock: stockDosha});

        let pair = {question: this.state.currentQuestion, answer: answer.value};
        this.user.questions.push(pair);

        this.assignScore(this.state.currentQuestion.dosha.slug, answer.value);

        if ( render ) {

            wrap.style.opacity = '0.5';
            wrap.style.cursor = 'wait';

            setTimeout( () => {
        
                    this.renderNext();

                    wrap.style.opacity = '1';
                    wrap.style.cursor = 'default';

            }, 300);
        }
    }


    handlePrevious() {

        let wrap = document.getElementById('dosha-test');
        let answer = document.getElementById('answer');

            wrap.style.opacity = '0.5';
            wrap.style.cursor = 'wait';

        setTimeout( () => {

            let userPrevious = this.user.questions[this.user.questions.length-1];

            this.setState({currentQuestionIndex: this.state.currentQuestionIndex-1});
                this.setState({currentQuestion: userPrevious.question});
                answer.value = userPrevious.answer;

                wrap.style.opacity = '1';
                wrap.style.cursor = 'default';

        }, 400);
        
    }


    renderNext() {

        let answer = document.getElementById('answer');
        answer.value = 50; // reset range

        let nextQuestion = this.drawQuestion();

        this.setState({currentQuestion: nextQuestion});
        this.setState({currentQuestionIndex: this.state.currentQuestionIndex+1});
    }


    assignScore(dosha, points) {

        this.user.dosha[dosha] = this.user.dosha[dosha] + parseInt( points );
    }

    test() {

        let tries = 10;

        for(let i=0; i<= tries;i++) {
            let doshas = {
                vata: parseInt( Math.random() * (100 - 1) + 1 ),
                pitta: parseInt( Math.random() * (100 - 1) + 1 ),
                kapha: parseInt( Math.random() * (100 - 1) + 1 )
            };
            console.log( doshas );
            console.log( this.findDominant( doshas ) );
        }

    }


    findDominant( userResult ) {

        let result = [];
        let dominant = [];
        
        for (let dosha in userResult) {

            let _dosha = {
                            score:  userResult[dosha], 
                            name:   dosha.charAt(0).toUpperCase() + dosha.slice(1)
                        };

            result.push( _dosha ); 
        }
        
        let userDoshaSorted = result.sort((a,b) => (a.score < b.score) ? 1 : ((b.score < a.score) ? -1 : 0));
        let topDoshaScore = result[0].score;

        let dualThreshold = topDoshaScore * this.ratio.dual;
        let triThreshold = topDoshaScore * this.ratio.tri;

        for(let i=0;i<userDoshaSorted.length;i++) { 
            
            let diff = topDoshaScore - userDoshaSorted[i].score;

            if( 
                diff <= ( (i+1) * dualThreshold ) 
                || diff <=  triThreshold
            ) {
                dominant.push( userDoshaSorted[i] );
            }
        }

        let _user = this.user;
            _user.dominant = dominant;

        this.setState({user: _user});

        let doshaSlugs = dominant.map(dosha => {
            return dosha.name.toLowerCase(); 
        });
        doshaSlugs.sort();

        this.setState({doshaCombo: doshaSlugs.join('-')});



    return dominant;
    }


    resultChartData() {
        return  {
            datasets: [{
                data: [this.user.dosha.vata, this.user.dosha.pitta, this.user.dosha.kapha],
                backgroundColor: ['#FFE993', '#E1888A', '#8BCEDF']
            }],
            labels: [
                'Vata',
                'Pitta',
                'Kapha'
            ]
        };
    }

    resultChartOptions() {

        return {
            plugins: {
                labels: {
                    render: 'label',
                    fontColor: 'white',
                    fontSize: 15,
                    fontStyle: 'bold',
                }
            },
            legend: {
                display: false
            },
            layout: {
                padding: {
                    left: 0,
                    right: 0,
                    top: 0,
                    bottom: 0
                },
            },
            tooltips: {
                enabled: true,
                mode: 'single',
                callbacks: {
                  label: function(tooltipItem, data) {
                    
                    var allData = data.datasets[tooltipItem.datasetIndex].data;
                    var tooltipLabel = data.labels[tooltipItem.index];
                    var tooltipData = allData[tooltipItem.index];
                    var sum = allData.reduce((a, b) => a + b, 0);
                    var perCent = (tooltipData / sum ) * 100;
                    return tooltipLabel + ": " + parseFloat(perCent).toFixed(0) + "%";
                  }
                }
              }
        }
    }


    render() {

        let question = this.state.currentQuestion;
        let icon = null;
        let isPrev = this.state.currentQuestionIndex > 1;
        let isNext = this.state.currentQuestionIndex < this.limit;
        let isLast = this.state.currentQuestionIndex === this.limit;
        let questionType = question.type;
        let currentQuestionNo = this.state.currentQuestionIndex;

        switch( question.dosha.slug ) {
            case 'vata':    icon = iconVata;   break;
            case 'pitta':   icon = iconPitta;  break;
            case 'kapha':   icon = iconKapha;  break;
        }

        const renderPrevButton = () => {

            let styles = isPrev ? {visibility: 'visible'} : {visibility: 'hidden'};

            return  <div className="btn-wrap right" style={styles}>
                            <button className={`btn white`} onClick={this.handlePrevious}>
                                <svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M 5.9999586,11 0.99995859,6 5.9999586,1" stroke="#ffffff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg><span>Previous question</span>
                            </button>
                        </div> 
        }

        const renderNextButton = () => {
            if(isNext) {
                return  <div className="btn-wrap">
                            <button className={`btn `} onClick={this.handleNext}><span>Next question</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 11L6 6L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </button>
                        </div>
            }
        }

        const renderResultButton = () => {
            if(isLast) {
                return  <div className="btn-wrap">
                            <button className={`btn secodary`} onClick={this.handleResult}><span>Complete Dosha Test</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 11L6 6L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                            </button>
                        </div>
            }
        }

        if( this.state.testStatus === 'answering' ) {

        return (
            <div className="container">
                <section id="dosha-test" className={"dosha-test-wrap " + this.state.testStatus}>
                    <div className="dosha-test-header">
                        <div className="test-icon">
                            <img src={icon} alt={question.dosha.name} title={question.dosha.name}/>
                        </div>
                        <div className="dosha-test-meta">
                            <div className="title">Dosha test: {questionType}</div>
                            <div className="subtitle">Question: {currentQuestionNo}/{this.limit}</div>

                        </div>
                    </div>
                    <div className={`question-wrap ${question.title} ${question.dosha.slug}`}>
                        <h3 className="question" dangerouslySetInnerHTML={{ __html: question.copy }}></h3>
                       
                        <div className="answer-controls">
                                <div className="not-at-all"><span>Not at all</span></div>
                                <div className="range-wrap"><input type="range" id="answer" name="vol" defaultValue="50" min="1" max="100"/></div>
                                <div className="very-much"><span>Very much</span></div>
                        </div>
                    </div>            
                    <div className='question-controls'>
                        {renderPrevButton()}
                        
                        {renderNextButton()}

                        {renderResultButton()}
                    </div>
                    <div className="dosha-support">
                        <h4>Dosha Test FAQ</h4>
                        <DoshaTestAccordion/>
                    </div>
                </section>
            </div>
        )

        } else if( this.state.testStatus === 'completed' ) {

            
            let doshaType = this.state.user.dominant.map(dosha => {
                return <span key={dosha.name} className={"dosha " + dosha.name}>{dosha.name}</span>; 
            });

            let doshaTypeSufix = '';
            
                switch( this.state.user.dominant.length ) {
                    case 1: doshaTypeSufix += ' '; break;
                    case 2: doshaTypeSufix += ' Dual-dosha'; break;
                    case 3: doshaTypeSufix += ' Tri-dosha'; break;
                }

            let doshaTypeHtml = <div className="dosha-wrap">{doshaType}</div>;

            const renderExplanation = () => { console.log('dosha', this.state.doshaCombo);
                
                if( this.state.doshaCombo === 'vata' ) {
                    return (<div  className="dosha-type-explanation" data-rel="vata">
                    <p>Vata body type (somatotype) can be best described as an ectomorph which is characterised by a “thin tall frame” 
                        and some difficulty in gaining more muscles or weight. Vata means “air” (vayu in sanskrit) so you are naturally 
                        mobile, quick-thinking and fast-walking. Your blood pressure is feeble. Your mind is restless, switching 
                        unexpectedly from one thought to another and not letting you complete sentences. You will have immediate bursts 
                        of energy level which allow you to do many things, but also exhaust you quickly. You need to fuel your body 
                        frequently to keep the energy level high. Vata takes pleasure in sexual and sensual enjoyments. 
                    </p>
                    <p>
                        The most common dreams involve images of residing in mountains: in trees and the air or of flying without any 
                        mechanical equipment. Vata dislikes cold weather and cold food. The favourite food preference includes sweet, 
                        sour, hot and pungent tastes. Vata normally makes sounds when drinking water. You’re very creative, 
                        motivated and adaptable. Vata knows how to go with the flow and be spontaneous. You have a high level of 
                        sensitivity but that also means you worry too much. You like changes and that impacts your sleeping and
                        eating patterns which are not stable. You sleep little, eat too much or too little and talk too much. 
                        Your feelings drift from one place to another. Individuals with this dosha tend to be unpredictable and insecure. 
                        You most likely spend money quickly and then live poor. You’re a fast learner but also have a weak memory. 
                        Unfortunately, this dosha type has a short life span. 
                    </p>
                    <p>If your vata is in balance it makes you vivacious and excited, dynamic, 
                        fidgeted and allows you to close many challenging tasks. Being out of balance means your bowels and skin 
                        are dry and hands and feet cold. You might also develop an excessive thirst, shaking, 
                        redness of skin or different ailments of ears, nose and throat. Untreated continuation of 
                        this balance may lead you to roughness of the voice, darkening of the skin, pulsing sensation or overall weakness.
                    </p>
                    </div> ) 
                } else if( this.state.doshaCombo === 'pitta' ) {
                    return (<div  className="dosha-type-explanation" data-rel="pitta">
                            <p>Pitta body type (somatotype) can be described as a mesomorph which is characterised by a medium built with 
                                loose joints and muscles and very proportional features. Pitta is dominated by bile. 
                                The sanskrit word “pitta” means “fire” so you naturally have an angry, aggressive and jealous personality, 
                                have a hot body and sweat profusely. Your hair and skin is fair, soft and oily. Unlike vata, 
                                your energy levels are stable. Your vigour and sexual desire are not big. You like flowers and scents. 
                                You are a born leader or public speaker equipped with a sharp intellect, concentrated mind and good memory. 
                            </p>
                            <p>
                            Pittas pay much attention to nuts and bolts and can multitask different activities simultaneously. 
                            You will complete the most challenging tasks with flying colours thanks to being organised, efficient and decisive. 
                            People generally like you as you are outgoing, kind, affectionate and passionate about many things. 
                            You have a character with a spiritual outlook and don’t follow common trends, religion etc. You are also brave and proud. 
                            Pitta dosha is fond of sweet, bitter and astringent tastes as well as cold drinks. 
                            There are several things that drive you in life and you always stay very vigilant. 
                            You dream about fire, shooting stars, thunder, sun, moon, glossy objects or poisonous plants. 
                            Pitta in your body means that you are likely to display colour in the form of freckles, spots, red eyes (when angry or exposed to sun),
                            red face when exercising, red hair, grey or white hair. 
                            </p>
                            <p>You are prone to go bald and grey at an early age. The average lifespan of a pitta person is average. 
                                You like spending money on luxurious items and making money by using your creative ways of working. 
                                Unfortunately, pitta also means that you tend to get irritated easily. 
                                When your dosha is imbalanced you become judgemental, impatient and angry.
                                You can also have a burning sensation, acidity, skin problems, indigestion or feel thirsty or hysteric. 
                                Untreated continuation of this balance may lead you to yellow eyes, skin, stool and urine as well as insomnia, 
                                fainting or dysfunctions in sense organs. 
                            </p>
                        </div>)

                } else if( this.state.doshaCombo === 'pitta' ) {

                    return (<div  className="dosha-type-explanation" data-rel="pitta">
                        <p>Kapha body type (somatotype) can be described as an endomorph which is characterised by a solid construction of 
                            the body with firm muscles (sometimes too much fat). Kapha is dominated by mucus. As kapha you have strong bones, 
                            smooth skin and large eyes. Your hair is oily, thick and wavy. Your skin colour is brass, gold or lotus flower. 
                            Kapha people are physically attractive.
                        </p>
                        <p>
                            Kapha means “earth” and “water” and those elements make you slow-moving and vulnerable to sound sleeping for a long time. 
                            Kapha is rarely disrupted by the feeling of unger, thirst, and pain. You have a forgiving disposition and you always honour your word. 
                            Kapha dislikes cool and damp weather and ambient. You do things slowly and patiently, your mind is stable. You are sober and humble by nature. 
                            Your learning process is extended and slow but once you absorb the information, you retain it for a very long time. 
                            People like being around you because you are calm, soft-spoken, tolerant and relaxed. You try to follow principles of the good, righteous living.
                        </p>
                        <p>Kapha can form loyal relationships, love sexual pleasures, is patient and compassionate. 
                            The favourite taste of this dosha is bitter, astringent and dry. Your emotions are absolutely balanced 
                            and you don’t frequently get angry. You have a positive outlook on life in general. You dream about water, 
                            waterbirds and lotuses. Imbalance in your kapha dosha means you put on weight, sleep too much and start getting 
                            lazy. It can also make you numb, itchy or cause nausea, anorexia, loss of memory or rigidity in joints. 
                            Untreated continuation of this balance may lead you to heaviness in limbs, cold sensation, excessive sleep, 
                            drowsiness and pallid complexion. 

                        </p>
                    </div>)

                } else if( this.state.doshaCombo === 'pitta-vata' ) {
                    return (<div  className="dosha-type-explanation" data-rel="pitta-vata">
                                <p>The first one in the pair is dominant over the other. 
                                    If the vata level in your body is higher than pitta, it might mean you have a slim body type but also a 
                                    better protection from the external environmental impacts. It can also determine that you are more stable in 
                                    terms of emotions. Your digestion may be stronger thanks to the fire element.
                                </p>
                                <p>If pitta prevails in your body, you are likely to have a sharp mind and digestion, 
                                    but vata dosha can add a creative talent and inconsistent eating and elimination habits. 
                                    Some of vata and pitta dosha qualities may overlap but some will contradict each other 
                                    e.g. dryness in vata against oiliness of pitta. They will frequently balance each other out. 
                                </p>
                            </div>)

                } else if( this.state.doshaCombo === 'kapha-vata' ) {
                    return (<div  className="dosha-type-explanation" data-rel="kapha-vata">
                                <p>The first one in the pair is dominant over the other. 
                                    Vata/kapha dosha normally means you have a slim build and you are an easy-going person. 
                                    You might suffer from frequent colds/flu and do not feel comfortable in winter. Additionally, 
                                    you could feel anxiety.
                                </p>
                                <p>On the opposite, if your kapha dominates vata you may have rounded body shapes with changing weight. 
                                    You will be well-balanced in terms of rest, relaxation, activity and creativity. In this dual dosha mix, 
                                    the cold element overlaps which intensifies the feeling of coldness. Both dosha features can balance each 
                                    other perfectly: vata is light, rough and dry while kapha is heavy, smooth and oily.
                                </p>
                            </div>)
                } else if ( this.state.doshaCombo === 'kapha-pitta' ) {

                    return (<div  className="dosha-type-explanation" data-rel="kapha-pitta">
                            <p>The first one in the pair is dominant over the other. 
                                If your pitta dominantes kapha, you are likely to have a well-proportioned body shape. 
                                Your drive and endurance are also well mixed. Your intellect is still sharp but the kapha part 
                                impacts you in a positive way making you an easy-going person. 
                            </p>
                            <p>In case your kapha prevails, your face can be round with a peaked nose. Your hunger can be quite intense and 
                                you digest food well but you are still prone to put on weight. 
                                Some aspects may overlap e.g. oiliness, but others will contradict each other: heat, 
                                lightness and sharpness of pitta with coolness, sluggishness and heaviness of kapha.
                            </p>
                        </div>)

                } else if ( this.state.doshaCombo === 'kapha-pitta-vata' ) {

                    return (<div  className="dosha-type-explanation" data-rel="kapha-pitta-vata">
                            <p>Every person has elements of all three doshic energies. Pita drives the metabolism, 
                                kapha owns the body structure and vata is responsible for the movement. 
                                No human being could exist without all three energies. In the tri-dosha combination all doshas are 
                                proportioned equally. It is an infrequent mixture and if you have one you’re quite lucky. 
                                Your body constitution is fit and vigorous. Keeping all three dosha in a proportional balance means good health. 
                                The only disadvantage is that any imbalance caused can be more difficult to handle and fix.
                            </p>
                        </div>)
                }
            }
        
            return (
                <div className="container">
                    <section id="dosha-test" className={`dosha-test-wrap ` + this.state.testStatus}>
                        
                        <div className={`question-wrap`}>
                            <div className="result-label" >Your dosha is {doshaTypeSufix}</div>
                            {doshaTypeHtml}
                        </div>     

                        <div className="result-content">
                            <div className="result-left">
                                <h3>Your Dosha Test Result Explanation</h3>
                                
                                {renderExplanation()}
                               
                            </div>
                            <div className="result-chart">
                                <Pie data={this.resultChartData()} options={this.resultChartOptions()}/>
                            </div>
                        </div>

                        {/* <div className='question-controls'>
                            <div className="btn-wrap">
                                <button className={`btn `} onClick={this.handleResult}><span>Share</span><svg width="7" height="12" viewBox="0 0 7 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1 11L6 6L1 1" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path></svg>
                                </button>
                            </div>
                        </div> */}
                    </section>
                </div>
            )
        
        } else return (
            <div className="container">
                
            </div>
        )
    }
}

export function DoshaTestAccordion() {

    const classes = makeStyles((theme) => ({
        root: {
          width: '100%',
          maxWidth: '600px',
          
        },
        elevation1: {
            boxShadow: 'none'
        },
        heading: {
          fontSize: theme.typography.pxToRem(20),
          fontWeight: theme.typography.fontWeightRegular,
          fontFamily: 'DM Sans'
        },
        rows: {
            flexDirection: 'column'
        },
        li: {
            padding: '2px 8px'
        },
        details: {
            fontFamily: 'DM Sans',
            fontSize: theme.typography.pxToRem(20),
            width: '100%'
        }
      }))();
  
    return (
      <div className={classes.root}>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>What is Dosha in Ayurveda?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>
              In Ayurveda “dosha” means the natural and original energetic type of every man. Dosha is divided into three kinds: Pitta, Kapha, Vata. Each of them is characterised by different psychological and physical features, dynamics of metabolism or body tolerance for external factors such as weather, occupation or relationship with other people.
Depending on the intensity of those features, either one type of dosha or a combination of them will dominate in a human body. Prevalence of two doshas is called “dual dosha”. The most balanced and favourable type is “tri dosha”, where all three kinds are present in more less equal proportions.
Based on your dosha type, Ayurveda explains how to keep your individual balance to enrich your health, wellbeing and allow you to live in harmony with nature.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Typography className={classes.heading}>What is the purpose of the Online Dosha Test?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>
            Thanks to Online Dosha Test by using the web browser you can check by yourself what type of dosha you are according to Ayurveda. All questions have been created based on multiple publications and in agreement with Ayurvedic consultants with long-term experience. 
The result of the test can be saved in your profile and you can decide about its visibility i.e. when consulting an Ayurvedic doctor or registering for yoga retreat.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>How to use the Dosha Test?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>
              In order to do Online Dosha Test you will need a computer with Internet access and a web browser. The test consists of 27 randomly selected questions coming from three pools, 9 for each dosha type. Each question will be displayed on a separate screen and comprises a category, an actual question and a scroll bar with the answer.
Every question must be answered by using the scroll bar and sliding it either left (“not at all”) or right (“very much”), according to your own assessment. 
After selecting the answer you have to go to the next question by clicking a button.
Answering the last question will allow you to show the instant result on the screen thanks to the “show result” button.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>What is output of the Dosha Test?</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.rows}>
            <Typography >
                After answering all questions an instant result of the dosha test will be displayed.
                <br/>
                The result page will contain:
            </Typography>
            <List className={classes.details} aria-label="contacts">
                <ListItem className={classes.li}>
                    <ListItemText primary="- a name of a dosha/doshas dominating in your body based on provided answers" />
                </ListItem>
                <ListItem className={classes.li}>
                    <ListItemText primary="- a graph depicting the layout of all doshas" />
                </ListItem>
                <ListItem className={classes.li}>
                    <ListItemText primary="- a short description of your dosha" />
                </ListItem>
                <ListItem className={classes.li}>
                    <ListItemText primary="- a link with an option to save the result in your profile" />
                </ListItem>
                <ListItem className={classes.li}>
                    <ListItemText primary="- a link with an option to share results" />
                </ListItem>
            </List>
            
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>How can I use the Dosha Test result?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>
            Having the right knowledge about the body constitution you can look after your health in the appropriate way. Every human being has a unique and individual nature which triggers different needs to keep a body in complete health. The result of this test will help you to determine a dosha type and then adjust to the recommended diet and lifestyle. The recommendation is different for each dosha and what might be beneficial for one, can become a poison for the other. It is all about choosing the right ingredients or lifestyle ways to keep your constitution in a perfect balance. There is one simple rule in Ayurveda: similar intensifies similar, and opposites attract. For example, a person with a dominant pitta dosha with warm and oily nature should avoid food products with similar features as it might aggravate that dosha. A detailed prescription of what to eat and how to live can be consulted with the Ayurvedic doctor or found in many available publications.
            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Can I share the Dosha Test result?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>
              
If you are logged in to your account your result will be saved automatically. You can decide about the visibility of your result on your profile page. By default your result is only visible to you.
If you're not logged in, you can create an account, so your result will be saved after the account creation confirmation.

            </Typography>
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
          >
            <Typography className={classes.heading}>Online Dosha Test is free of charge?</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography className={classes.details}>Online Dosha Test on asana.travel is entirely free of charge. </Typography>
          </AccordionDetails>
        </Accordion>
        
      </div>
    );
  }


export default DoshaTest;



