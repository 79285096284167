
const siteTitle = `Yoga Holidays and Retreats`;

export function getPageTitle( page ) {
        
    let pageTitle = page && page.seo.title ?  encodeSpecialChars(page.seo.title) : `${page.title} ${String.fromCharCode(8226)} ${siteTitle}`;

return pageTitle;
}


function encodeSpecialChars( _string ) {

    return _string.replace(/&amp;/g, '&');
}