import React from 'react'
import { Helmet } from "react-helmet"
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Layout from '../layouts/layout'
import Wrapper from '../templates/wrapper'
import DoshaTest from '../modules/module-dosha-test'
import Content from '../components/content/content-default'
import { getPageTitle } from '../libs/Seo';

Helmet.defaultProps.encodeSpecialCharacters = false;

Content.propTypes = {
  title: PropTypes.string,
  content: PropTypes.string,
}


const Page = ({ data }) => { 
  const { wpPage: page, allWpQuestion: questions } = data
 
  let allQuestions = prepareQuestions( questions );

  return (
    <Layout page={page} type="boxed single dosha-test" headerStyle="simple" source="question">
      <Helmet 
        encodeSpecialCharacters={false}
      >
          <meta charSet="utf-8" />
          <title >{getPageTitle(page)}</title>
      </Helmet>
      <Wrapper query={data}>
        <Content>
          {page.content}
        </Content>
        <DoshaTest questions={allQuestions}/>
      </Wrapper>
    </Layout>
  )
}


Page.propTypes = {
  data: PropTypes.object.isRequired,
}


export function prepareQuestions( questions ) {

    let preparedQuestions = [];

    if( ! questions.nodes ) return false;


    for(let question of questions.nodes) {

        let dosha_slug = question.terms ? question.terms.nodes[0].slug : null
        let dosha_name = question.terms ? question.terms.nodes[0].name : null

        let _question = {
            id:     question.databaseId,
            title:  question.title,
            copy:   question.content,
            dosha:  {
              slug: dosha_slug,
              name: dosha_name
            },
            type: question.dosha_part_meta.doshaPart
        };

        preparedQuestions.push( _question );
    }

return preparedQuestions;
}


export default Page


export const pageQuery = graphql`
  query CustomPageById($id: String!) {
    wpPage(id: { eq: $id }) {
      databaseId
      title
      content
      excerpt
      meta {
        metaTemplate
      }
      featuredImage {
          node {
              sourceUrl
              title
              altText
          }
      }
      seo {
        title
      }
    }
    allWpQuestion(filter: {status: {eq: "publish"}}) {
      nodes {
        content
        databaseId
        terms {
          nodes {
            id
            ... on WpDosha {
              name
              slug
            }
          }
        }
        title
        dosha_part_meta {
          doshaPart
        }
      }
    }
  }
`
